<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700 a-ptb-6">商户分期</span>
        </div>
        <el-card class="el-main goodsOrder">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-company-under-select placeholder="请选择（可输入搜索）" label="商户" v-model="pageParam.params.companyId"></le-company-under-select>
            </le-search-form>
            <le-pagview ref="financeRePayment" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.goodsOrderList">
                <el-table ref="goodsOrderList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column label="商户名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="分期总金额（设备费用）" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ utils.numFormat(row.financeAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="已还款总金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ utils.numFormat(row.repaymentAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="年服务费" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ utils.numFormat(row.serviceAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="到期未还金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ utils.numFormat(row.expireAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="70" fixed="right" label="操作" class="a-flex-rcc">
                        <template slot-scope="{ row }">
                            <el-tooltip class="item" effect="dark" content="还款详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toDetails(row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>
<script>
import utils from '../../utils/util'
export default {
    data() {
        return {
            utils: utils,
            tableData: [],
            companyId: '',//
            pageParam: null
        }
    },
    created() {
        this.pageParam = {
            url: this.$Config.apiUrl.getFinanceWallet,
            method: "post",
            params: {
                companyId: '',
            },
            freshCtrl: 1,
        };
    },
    activated () {
        this.pageParam.freshCtrl++;
    },
    methods: {
        setTableData(data) {
            this.tableData = data;
        },
        handlerRest() {
            this.pageParam.params = {
                companyId: ''
            };
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['financeRePayment'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        toDetails (datas) {
            this.$router.push({
                path:'/finance/finance-repayment-details',
                query: {
                    companyId: datas?datas.companyId:''
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.goodsOrder {
    padding: 8px 4px;
}
.s-btn-add{
    width: 105px;
}
.gocompany {
    color: #007AFF;
    cursor: pointer;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>